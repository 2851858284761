<template>
  <v-dialog
    v-model="shouldShowDialog"
    :persistent="isLoading"
    :max-width="isDesktopDevice ? 800 : 600"
  >
    <v-card :loading="isLoading">
      <v-card-title class="primary white--text">
        <v-icon dark class="mr-3">
          contact_mail
        </v-icon>

        Contact Us!
      </v-card-title>

      <v-card-text class="pt-4 pt-md-6 pt-lg-8">
        <v-row class="flex-md-row-reverse">
          <v-col
            cols="12"
            md="6"
            class="d-flex align-end justify-center"
          >
            <div style="max-width: 360px">
              <lottie-animation
                loop
                height="200"
                file="support-desk.json"
              />
            </div>
          </v-col>

          <v-col
            cols="12"
            md="6"
          >
            <v-form
              :disabled="isLoading"
            >
              <template v-if="!isAuthenticated">
                <v-text-field
                  v-model.trim="form.name"
                  class="mb-3"
                  type="text"
                  :hide-details="!$v.form.name.$anyError"
                  :error-messages="$v.form.name.$anyError ? ['Please enter a valid name'] : null"
                  counter="200"
                  label="Name"
                  outlined
                  required
                  dense
                  @input="$v.form.name.$touch()"
                  @blur="$v.form.name.$touch()"
                ></v-text-field>

                <v-text-field
                  v-model.trim="form.company_name"
                  class="mb-3"
                  type="text"
                  :hide-details="!$v.form.company_name.$anyError"
                  :error-messages="$v.form.company_name.$anyError ? ['Please enter a valid company name'] : null"
                  counter="200"
                  label="Company Name"
                  outlined
                  required
                  dense
                  @input="$v.form.company_name.$touch()"
                  @blur="$v.form.company_name.$touch()"
                ></v-text-field>

                <v-text-field
                  v-model.trim="form.email"
                  class="mb-3"
                  type="email"
                  :hide-details="!$v.form.email.$anyError"
                  :error-messages="$v.form.email.$anyError ? ['Please enter valid email address'] : null"
                  counter="200"
                  label="Email Address"
                  outlined
                  required
                  dense
                  @input="$v.form.email.$touch()"
                  @blur="$v.form.email.$touch()"
                ></v-text-field>
              </template>

              <v-textarea
                v-model.trim="form.message"
                :hide-details="!$v.form.message.$anyError"
                :error-messages="$v.form.message.$anyError ? ['Please enter valid message'] : null"
                counter="1000"
                label="Message"
                :rows="isAuthenticated ? (isDesktopDevice ? 8 : 5) : 3"
                outlined
                required
                dense
                @input="$v.form.message.$touch()"
                @blur="$v.form.message.$touch()"
              ></v-textarea>
            </v-form>
          </v-col>
        </v-row>
      </v-card-text>

      <v-card-actions>
        <v-spacer />

        <v-btn
          text
          depressed
          class="mr-3"
          color="primary"
          @click="shouldShowDialog = false"
        >
          Cancel
        </v-btn>

        <v-btn
          depressed
          color="primary"
          :loading="isLoading"
          :disabled="isLoading"
          @click="handleSubmit"
        >
          Send
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
// Import helper functions
import { email, required, minLength, maxLength } from "vuelidate/lib/validators"

// Import children components
const LottieAnimation = () => import(/* webpackChunkName: "lottie-animation" */ "@/components/common/LottieAnimation.vue")

// Define default form inputs
const generateForm = () => ({
  name: "",
  email: "",
  company_name: "",
  message: ""
})

// Export the SFC
export default {
  // Name of the component
  name: "ContactForm",

  // Register the children components
  components: {
    LottieAnimation
  },

  // Define local data variables
  data: () => ({
    isLoading: false,
    shouldShowDialog: false,

    form: generateForm()
  }),

  // Define computed properties
  computed: {
    /**
     * Whether or not the user is logged in
     *
     * @returns {boolean}
     */
    isAuthenticated() {
      return this.$store.getters["auth/isAuthenticated"]
    },

    /**
     * Get the authenticated user's data
     *
     * @returns {boolean}
     */
    profile() {
      return this.$store.getters["auth/profile"]
    }
  },

  // Define validations for the form
  validations() {
    const object = {
      form: {
        message: {
          required,
          minLength: minLength(2),
          maxLength: maxLength(1000)
        }
      }
    }

    // If the user is not authenticated
    if (!this.isAuthenticated) {
      object.form.name = {
        required,
        maxLength: maxLength(200)
      }

      object.form.email = {
        email,
        required,
        maxLength: maxLength(200)
      }

      object.form.company_name = {
        required,
        maxLength: maxLength(200)
      }
    }

    return object
  },

  // Define local methods
  methods: {
    /**
     * Handle the form submission
     *
     * @returns {Promise<void>}
     */
    async handleSubmit() {
      await this.$v.$touch()

      if (this.$v.$anyError) {
        this.$store.dispatch("toasts/add", { text: "Please enter correct values" })

        return
      }

      // Show a loader while the request is being processed
      this.isLoading = true

      const loaderId = Symbol()
      await this.$store.dispatch("loaders/add", loaderId)

      // Update the input values
      if (this.isAuthenticated) {
        this.form.name = this.profile.name
        this.form.email = this.profile.email
        this.form.company_name = this.profile.company_name
      }

      try {
        await axios({
          url: "/api/contacts",
          method: "POST",
          data: this.form
        })

        this.shouldShowDialog = false
        this.form = generateForm()

        this.$store.dispatch("toasts/add", { text: "Message received. We'll get back to you soon!" })
      } catch (error) {
        logger({ type: "Contact Form Error", error })

        // show the error message to the user
        this.$store.dispatch("toasts/add", { text: error.response?.data?.message ||"An Error Occurred." })
      } finally {
        // remove the loader
        await this.$store.dispatch("loaders/remove", loaderId)

        // enable the button
        this.isLoading = false
      }
    }
  },

  /**
   * As soon as the component is mounted
   *
   * @returns {void}
   */
  mounted() {
    // Listen for the event to show the contact form
    window.addEventListener("showContactForm", () => {
      this.shouldShowDialog = true
    })
  }
}
</script>
